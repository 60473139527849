import React from 'react'
import ReactStars from 'react-stars'

import { FormInput, FormRow, FormCol } from 'components/shared/Forms'

export default (rating, name, onChangeStars) => (
  <FormRow>
    <FormCol>
      <FormInput label="Give a rating">
        <ReactStars
          count={5}
          value={rating}
          name={name}
          required
          onChange={newRating => this.props.onChangeStars(newRating, name)}
          size={30}
          color1={'#E0E0E0'}
          color2={'#56578d'}
        />
      </FormInput>
    </FormCol>
  </FormRow>
)
