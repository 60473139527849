import React from 'react'

import { ReviewFormElement, StarsFormElement } from '../elements'
import { FormButtonsElement } from 'components/shared/Forms'
import UserBlurb from 'components/Users/UserBlurb'

export default props => {
  const { rental, initialData } = props
  const { item_review, item_rating } = initialData
  let { item_name, item_img } = rental || {}
  let user = {
    firstName: item_name,
    picture: item_img,
  }
  return (
    <div>
      <h3>Leave a review for the {item_name}</h3>
      <UserBlurb user={user} />
      <StarsFormElement
        name="item_rating"
        rating={item_rating}
        onChangeStars={props.onChangeStars}
      />
      <ReviewFormElement
        name="item_review"
        itemReview={item_review}
        onChange={props.onChange}
      />
      <FormButtonsElement
        {...this.props}
        nextText="Save & Continue"
        disabled={item_rating == null || item_review == ''}
      />
    </div>
  )
}
