import React from 'react'
import { FormInput, FormRow, FormCol } from 'components/shared/Forms'

export default (review, name) => (
  <FormRow>
    <FormCol>
      <FormInput label="Describe the experience">
        <textarea
          className="text-input"
          type="text"
          name={name}
          maxLength="1600"
          rows="6"
          required
          defaultValue={review}
          onChange={this.props.onChange}
        />
      </FormInput>
    </FormCol>
  </FormRow>
)
