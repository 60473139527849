import React, { Component } from 'react'
import { Link } from 'gatsby'

import { ItemReviewStep, UserReviewStep, ConfirmationStep } from './steps'
import { FormContainer, Alert } from 'components/shared/Forms'
import Card from 'components/shared/Card'

import { LoadingDots } from 'components/shared/Loading'
import keys from 'constants/keys'

export default class ReviewForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      currentStep: 1,
      item_review: '',
      item_rating: null,
      user_review: '',
      user_rating: null,
    }
  }

  _moveForward = () => {
    if (!this.form.reportValidity()) {
      return
    }
    if (window) {
      window.scrollTo(0, 0)
    }
    this.setState({ currentStep: this.state.currentStep + 1 })
  }

  _submit = async type => {
    if (this.state.item_rating != null || this.state.user_rating != null) {
      this.setState({ loading: true })
      this._moveForward()
      const review = {
        rental: this.props.rental,
        item_review: this.state.item_review,
        item_rating: this.state.item_rating,
        user_review: this.state.user_review,
        user_rating: this.state.user_rating,
      }
      ;(async () => {
        const error = await this.props.onSubmit(review, type)
        if (error) {
          this.setState({ error, loading: false })
          console.log(error)
        }
        this.setState({ success: true, loading: false })
      })()
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeStars = (newRating, name) => {
    this.setState({ [name]: newRating })
  }

  render() {
    const { currentStep } = this.state
    let { rental, reviewerType } = this.props
    return (
      <FormContainer width="500px">
        <Card>
          <form ref={el => (this.form = el)}>
            {currentStep == 1 ? (
              <UserReviewStep
                initialData={{ ...this.state }}
                rental={rental}
                reviewerType={reviewerType}
                onChange={this.onChange}
                onChangeStars={this.onChangeStars}
                submit={() => this._submit('user')}
              />
            ) : currentStep == 2 && reviewerType == 'renter' ? (
              <ItemReviewStep
                initialData={{ ...this.state }}
                rental={rental}
                onChange={this.onChange}
                onChangeStars={this.onChangeStars}
                submit={() => this._submit('item')}
              />
            ) : (
              <ConfirmationStep />
            )}

            <Alert
              type="error"
              show={this.state.error}
              message="We were not able to process your request at this time. Please
                try again later."
            />

            <LoadingDots loading={this.state.loading} />
          </form>
        </Card>
      </FormContainer>
    )
  }
}
