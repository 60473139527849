import React from 'react'

import { ReviewFormElement, StarsFormElement } from '../elements'
import { FormButtonsElement } from 'components/shared/Forms'
import UserBlurb from 'components/Users/UserBlurb'

export default props => {
  const { rental, reviewerType, initialData } = props
  let { user_review, user_rating } = initialData
  let user = {
    firstName: '',
    pictures: '',
  }
  if (reviewerType == 'renter') {
    user.firstName = rental.lender_name
    user.picture = rental.lender_img
  } else if (reviewerType == 'lender') {
    user.firstName = rental.renter_name
    user.picture = rental.renter_img
  }
  return (
    <div>
      <h3>Leave a review for {user.firstName}</h3>

      <UserBlurb user={user} />

      <StarsFormElement
        name="user_rating"
        rating={user_rating}
        onChangeStars={props.onChangeStars}
      />
      <ReviewFormElement
        name="user_review"
        userReview={user_review}
        onChange={props.onChange}
      />
      <FormButtonsElement
        {...props}
        nextText="Save & Continue"
        disabled={user_rating == null || user_review == ''}
      />
    </div>
  )
}
