import React from 'react'
import { navigate } from 'gatsby'

import SearchURLPreloader from 'components/shared/SearchURLPreloader'
import ReviewForm from 'components/Reviews/ReviewForm'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'

class ReviewPage extends React.Component {
  state = {
    rental: {},
    reviewType: null,
  }

  componentDidMount() {
    this.checkLenderOrRenter(this.props.rental)
  }

  checkLenderOrRenter = rental => {
    let current_id = this.props.currentUser.uid
    if (current_id == rental.renter.id) {
      this.setState({ reviewerType: 'renter' })
    } else if (current_id == rental.renter.id) {
      this.setState({ reviewerType: 'lender' })
    } else {
      navigate('/search')
    }
  }
  /**
   * Creates a review on the backend service
   */
  createReview = async (review, type) => {
    try {
      let { reviewerType } = this.state
      await this.props.database.createReview(review, type, reviewerType)
      this.setState({ success: true })
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <ReviewForm
        rental={this.props.rental}
        reviewerType={this.state.reviewerType}
        onSubmit={this.createReview}
      />
    )
  }
}

export default ({ location }) => (
  <SearchURLPreloader load={{ id: false }} location={location}>
    {({ searchData: { id: data }, ...props }) => (
      <Layout location={location}>
        <SEO title="Review" />
        <ReviewPage location={location} rental={data} />
      </Layout>
    )}
  </SearchURLPreloader>
)
